<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<div class="row justify-content-center">
			<div class="col-xl-3 col-md-6">
				<div class="widget-simple text-center card">
					<div class="card-body">
						<h3 class="text-success counter mt-0">
							{{ commissionInfo.total }}
						</h3>
						<p class="text-muted mb-0">{{ commissionInfo.text }}</p>
					</div>
				</div>
			</div>

			<div class="col-lg-9 col-sm-12">
				<div class="row">
					<div class="col-md-4 col-sm-6">
						<div class="form-group">
							<label for="branch">Please Select A Branch</label>
							<select
								class="form-control"
								name="branch"
								id="branch"
								v-model="selectedBranch"
							>
								<template v-for="(branch, index) in branches">
									<option :key="index" :value="branch._id">
										{{ branch.name }}
									</option>
								</template>
							</select>
						</div>
					</div>

					<div class="col-md-4 col-sm-6">
						<div class="form-group">
							<label for="branch">Start Date</label>
							<date-picker
								value-type="YYYY-MM-DD"
								format="YYYY-MM-DD"
								v-model="startDate"
								type="date"
								lang="en"
								placeholder="Select Start Date"
							/>
						</div>
					</div>

					<div class="col-md-4 col-sm-6">
						<div class="form-group">
							<label for="branch">End Date</label>
							<date-picker
								value-type="YYYY-MM-DD"
								format="YYYY-MM-DD"
								v-model="endDate"
								type="date"
								lang="en"
								placeholder="Select End Date"
							/>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<b-card>
							<h4 class="header-title">Commission Payout</h4>
							<p class="sub-header" v-html="tableSubtitle"></p>
							<b-input placeholder="Search here..." v-model="search" />
							<br />
							<b-table
								thead-class="red-t-head"
								:filter="search"
								outlined
								:per-page="perPage"
								:current-page="currentPage"
								hover
								:fields="tableHeaders"
								:items="tableData"
							></b-table>
							<b-pagination
								:total-rows="tableData && tableData.length"
								:per-page="perPage"
								v-model="currentPage"
							></b-pagination>
						</b-card>
					</div>
				</div>
			</div>
			<!-- end col -->
		</div>
	</Layout>
</template>

<script>
	import Layout from "../../../layouts/vertical";
	import PageHeader from "@/components/Page-header.vue";
	import { mapActions, mapGetters, mapState } from "vuex";
	import DatePicker from "vue2-datepicker";
	import Table from "@/components/tables/BasicTable.vue";
	import dayjs from "dayjs";
	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});
	/**
	 * Starter component
	 */
	export default {
		//Sets the title of the page
		page: {
			title: "Comission Payouts",
		},
		components: {
			Layout,
			PageHeader,
			DatePicker,
			Table,
		},
		data() {
			return {
				title: "Comission Payouts",
				items: [
					{
						text: "Commissions",
						active: true,
					},
				],
				commissionInfo: {
					text: "Total Commission Payout for this year",
					total: 0,
				},
				selectedBranch: "",
				selectedYear: "",
				startDate: null,
				endDate: null,
				//Commisssion table headers
				currentPage: 1,
				perPage: 5,
				search: "",
				tableHeaders: [
					{
						label: "Week Start",
						key: "startOfPeriod",
						formatter: (v) => {
							return dayjs(v).format("MMMM DD, YYYY");
						},
						sortable: true,
					},
					{
						label: "Week Ending",
						key: "endOfPeriod",
						formatter: (v) => {
							return dayjs(v).format("MMMM DD, YYYY");
						},
						sortable: true,
					},
					{
						label: "Gross Profit",
						key: "grossProfit",
						sortable: true,
						formatter: (v) => {
							return formatter.format(v);
						},
					},
					{
						label: "Commission",
						key: "commission",
						sortable: true,
						formatter: (v) => {
							return formatter.format(v);
						},
					},
					{
						label: "Net Profit",
						key: "profits",
						sortable: true,
						formatter: (v) => {
							return formatter.format(v);
						},
					},
				],
				tableData: [],
			};
		},
		async created() {
			await this.getBranches();
			// await this.getTransactions();
			if (this.branches[0]) this.selectedBranch = this.branches[0]._id;

			this.selectedYear = dayjs().format("YYYY-MM-DD");
			this.startDate = dayjs().startOf("year").format("YYYY-MM-DD");
			this.endDate = dayjs().format("YYYY-MM-DD");
			this.setCommissionCardData();
		},
		computed: {
			...mapState("transactionsModule", ["transactions", "transactionSummary"]),
			...mapState("branchModule", ["branches"]),
			...mapGetters("transactionsModule", ["getTransactionsByBranch"]),

			trasactionFilteredByYearAndBranch: function () {
				return this.getTransactionsByBranch(this.selectedBranch).filter(
					(transaction) =>
						new Date(transaction.transactionDate + " 00:00").getFullYear() ==
						new Date(this.selectedYear + " 00:00").getFullYear()
				);
			},

			tableSubtitle: function () {
				return `Breakdown of commission payouts from <b> ${dayjs(
					this.startDate
				).format("MMM DD, YYYY")} to ${dayjs(this.endDate).format(
					"MMM DD, YYYY"
				)}</b>`;
			},
		},

		methods: {
			...mapActions("transactionsModule", [
				"getTransactionSummary",
				"getMonthOrWeekTransaction",
				"getTransactions",
			]),
			...mapActions("branchModule", ["getBranches"]),

			/**
			 * Method used to get the transactons based on the
			 * Branch selected, the start date &the end date.
			 */
			async loadTableData() {
				try {
					const { data } = await this.getMonthOrWeekTransaction({
						branchId: this.selectedBranch,
						readingType: "REGULAR",
						period: "weekly",
						startDate: this.startDate,
						endDate: this.endDate,
					});
					this.tableData = [];
					this.tableData = data.filter(
						(t) => t.totalIn != 0 || t.totalOut != 0
					);
					this.tableData.forEach(
						(t) => (t.endOfPeriod = dayjs(t.endOfPeriod).format("YYYY-MM-DD"))
					);
					this.tableData.forEach(
						(t) =>
							(t.startOfPeriod = dayjs(t.startOfPeriod).format("YYYY-MM-DD"))
					);
					// sort table daa by week
					this.tableData.sort((a, b) => {
						return new Date(b.startOfPeriod) - new Date(a.startOfPeriod);
					});
				} catch (error) {
					console.log(error);
				}
			},
			/**
			 * Method used to set the total commission payout in card
			 */
			async setCommissionCardData() {
				try {
					const { data } = await this.getTransactionSummary({
						groupBy: "branch",
					});
					let bData = data.filter((b) => b.branch == this.selectedBranch)[0];
					this.commissionInfo = {
						text: `Total Commission Payout for ${dayjs().format("YYYY")}`,
						total: formatter.format(bData ? bData.commission : 0),
					};
				} catch (error) {
					console.log(error);
				}
			},
		},

		watch: {
			selectedBranch: async function (newVal, oldVal) {
				if (newVal != oldVal) {
					this.setCommissionCardData();
					this.loadTableData();
				}
			},
			startDate: async function (newVal, oldVal) {
				if (newVal != oldVal) {
					this.loadTableData();
				}
			},
			endDate: async function (newVal, oldVal) {
				if (newVal != oldVal) {
					this.loadTableData();
				}
			},
		},
	};
</script>
